<template>
  <div
    id="apps_page"
    class="card"
  >
    <b-card-header>
      <b-button
        v-b-modal.modal-add-new-app
        variant="success"
      >
        Add new
      </b-button>
      <!--      <b-button variant="primary" @click.prevent="search()"
            >Search
            </b-button>-->
    </b-card-header>
    <div class="table-responsive mb-0">
      <table
        role="table"
        :aria-busy="isBusy"
        class="table b-table table-striped table-hover"
      >
        <thead
          role="rowgroup"
          class=""
        >
          <tr
            role="row"
            class=""
          >
            <th
              role="columnheader"
              aria-colindex="1"
              :aria-sort="arialSort('app_id')"
              @click="sortCol('app_id')"
            >
              App Name
            </th>
            <th
              role="columnheader"
              aria-colindex="2"
            >
              Status
            </th>
            <th
              role="columnheader"
              aria-colindex="2"
              :aria-sort="arialSort('unique_users')"
              @click="sortCol('unique_users')"
            >
              30 Days Active Users
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('monetized_users')"
              @click="sortCol('monetized_users')"
            >
              30 Days Monetized Users
            </th>
            <th
              role="columnheader"
              aria-colindex="3"
              :aria-sort="arialSort('publisher_revenue')"
              @click="sortCol('publisher_revenue')"
            >
              30 Days Revenue
            </th>
            <th style="width: 13.571rem">
              Action
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <template v-for="(rowTr, indextr) in items">
            <tr
              :key="indextr"
              :class="rowTr.class"
              @click.prevent.stop="editData(rowTr)"
            >
              <td><a href="#">{{ rowTr.app_name }}</a></td>
              <td>
                <b-badge
                  pill
                  :variant="'light-' + rowTr.status_color"
                >{{ rowTr.status_text }}
                </b-badge>
              </td>
              <td>{{ rowTr.unique_users | formatCount }}</td>
              <td>{{ rowTr.monetized_users | formatCount }}</td>
              <td>
                {{
                  rowTr.publisher_revenue | formatCurrency
                }}
              </td>
              <td>
                <b-button
                  size="sm"
                  variant="primary"
                  @click.prevent.stop="editData(rowTr)"
                >Edit
                </b-button>
              <!--              <b-button
                                size="sm"
                                variant="danger"
                                @click.prevent.stop="deleteData(rowTr.hash)"
                            >Delete
                            </b-button>-->
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-card-footer>
      <b-row>
        <b-col
          v-show="items.length > 0"
          cols="12"
        >
          <pagination-and-table-info
            :data="paginationData"
            @change="handleChangePage($event)"
          />
        </b-col>
        <b-col
          v-show="items.length === 0"
          cols="12"
          style="font-weight: 500; text-align: center"
        >
          No data
        </b-col>
      </b-row>
    </b-card-footer>
    <b-modal
      id="modal-add-new-app"
      scrollable
      hide-footer
      hide-header
      size="md"
    >
      <add-new-app
        :data="sidebarData"
        @added_app="added_app"
      />
    </b-modal>
  </div>
</template>

<script>
import PaginationAndTableInfo from '@ps_main/custom-components/PaginationAndTableInfo.vue'
import AddNewApp from '@/views/apps/AddNewApp.vue'

export default {
  components: {
    AddNewApp,
    PaginationAndTableInfo,
  },
  data() {
    return {
      isBusy: false,
      params: {
        page: 1,
        orderBy: 'publisher_revenue',
        itemPerPage: 500,
        orderDirection: 'desc',
      },
      filters: {
        variables: {},
        initFilter: 0,
      },
      items: [],
      responseData: null,
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  computed: {
    paginationData() {
      return this.laravelPaginationPages(this.responseData)
    },
  },
  created() {
    this.search()
  },
  methods: {
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    async deleteData(appHash) {
      const result = await this.confirm('Are you sure?')
      if (result) {
        this.processDelete(appHash)
      }
    },
    processDelete(appHash) {
      this.showLoading()
      this.$http.delete(`/api/apps/delete/${appHash}`).then(response => {
        this.search()
        this.alertSuccess(response.data.message)
      }).catch(errors => {
        this.alertError(errors.response.data)
      })
    },
    editData(data) {
      this.$router.push({
        name: 'app-edit',
        params: {
          appHash: data.hash,
        },
      }).catch()
    },
    handleChangePage(page) {
      this.params.page = page
      this.search()
    },
    handleSort(key, active) {
      this.params.orderBy = key
      this.params.orderDirection = active
      this.search()
    },
    sortCol(fieldName) {
      if (this.params.orderBy === fieldName) {
        this.params.orderDirection = this.params.orderDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.params.orderBy = fieldName
        this.params.orderDirection = 'asc'
      }
      this.search()
    },
    arialSort(sortFieldName) {
      if (this.params.orderBy === sortFieldName) {
        return this.params.orderDirection === 'asc'
          ? 'ascending'
          : 'descending'
      }
      return 'none'
    },
    search() {
      this.isBusy = true
      this.showLoading()
      this.$http.get('/api/apps', {
        params: {
          ...this.params,
          ...this.filters.variables,
        },
      }).then(response => {
        this.hideLoading()
        this.items = response.data.data
        this.responseData = response.data
        this.isBusy = false
      }).catch(errors => {
        this.alertError(errors.response.data)
        this.hideLoading()
        this.isBusy = false
      })
    },
    added_app(data) {
      this.items.unshift(data.app)
      this.alertSuccess(data.message)
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
  },
}
</script>
